<template>
  <div class="flex flex-col contact-container min-h-screen h-full overflow-x-hidden">
    <div class="bg-deep-green">
      <CustomNav />
    </div>
    <div class="p-3 md:p-5 flex-1 flex flex-col md:flex-row-reverse justify-center gap-3 md:gap-10 items-start">
      <div
        class=" bg-white shadow-xl rounded-md py-4 md:py-20 flex flex-col justify-center items-center w-full md:w-8/12 px-3 md:px-5">
        <h3 class="text-lg md:text-3xl font-bold">We"d love to hear from you.</h3>
        <p class="font-light text-xs md:text-sm mt-2">Let"s know how we can be of help</p>

        <form @submit.prevent="submitForm" class="w-full px-2 md:px-5 mt-6 md:mt-10">
          <div class="flex flex-col">
            <label class="text-md font-light ml-1">Your Name</label>
            <input v-model="formData.name" type="text" placeholder="Name"
              class="border border-gray-400 py-2 px-2 rounded-lg text-sm" />
          </div>

          <div class="flex flex-col mt-4">
            <label class="text-md font-light ml-1">Email Address</label>
            <input v-model="formData.email" type="email" placeholder="Email"
              class="border border-gray-400 py-2 px-2 rounded-lg text-sm" />
          </div>

          <div class="flex flex-col mt-4">
            <label class="text-md font-light ml-1">Phone Number</label>
            <input v-model="formData.phone" type="tel" placeholder="Phone Number"
              class="border border-gray-400 py-2 px-2 rounded-lg text-sm" />
          </div>

          <div class="flex flex-col mt-4">
            <label class="text-md font-light ml-1">Your Message</label>
            <textarea v-model="formData.message" rows="10" placeholder="Type here..."
              class="border border-gray-400 py-2 px-2 rounded-lg text-sm" />
          </div>

          <div class="w-full flex justify-center items-center py-2">
            <button type="submit" :class="{ 'opacity-50 cursor-not-allowed': isLoading }" :disabled="isLoading"
              class="mt-4 py-2 px-4 bg-deep-green text-white rounded-lg">
              <span v-if="isLoading">Submitting...</span>
              <span v-else>Submit</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import CustomNav from "../components/CustomNav.vue";
import Footer from "../components/Footer.vue";
import { sendContactFormData } from "../utils/network";

export default {
  components: {
    CustomNav,
    Footer
  },
  data() {
    return {
      formData: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      isLoading: false,
    };
  },
  methods: {
    async submitForm() {
      this.isLoading = true;
      try {
        const response = await sendContactFormData(this.formData);
        this.$toast.success(response?.message || "Form submitted successfully!");
        this.$router.push("/");
      } catch (error) {
        if (error?.response?.data?.errors) {
          const errorMessages = Object.values(error.response.data.errors).flat();
          if (errorMessages.length > 0) {
            errorMessages.forEach((errorMessage) => {
              this.$toast.error(errorMessage);
            });
          } else {
            this.$toast.error("Validation error occurred.");
          }
        } else {
          this.$toast.error(error?.message || "Failed to submit the form. Please try again.");
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
}
</script>

<style lang="scss">
.contact-container {
  background-color: #EBF7FB;
}

.bg-deep-green {
  background: #0A78A1;
}
</style>