import Axios from "axios";

const prodURL = "https://api.senderrand.com/api/v1/";
const devURL = "http://206.189.125.156/api/v1/";

export const baseURL =
  process.env.NODE_ENV === "development" ? devURL : prodURL;

const apiService = Axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const sendContactFormData = async formData => {
  const response = await apiService.post("contact", formData);
  return response.data;
};
